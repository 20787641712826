<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>

			<div class="mt20 bg-w pd30" style="min-height: 800px;">
				<div>
					<a-alert message="请在右侧区域进行设置，左侧为展示区。二维码会在分销员分享的时侯自动替换成分销员的二维码" banner />
				</div>
				<div class="mt30">
					<div class="flex center">
						<div>
							<div class="edit-poster-canvas" ref="canvas">
								<img :src="form.back_img" v-if="form.back_img!= ''"
									class="edit-poster-canvas-back-img" />
								<img src="../../assets/image/kefu.png" class="edit-poster-canvas-qrcode" ref="qrcode"
									:style="{width : form.qrcode_info.width + 'px',height:form.qrcode_info.height + 'px',
								top : form.qrcode_info.y + 'px',left:form.qrcode_info.x + 'px'}" @mousedown="mouseDownHandleelse($event)"
									@mouseup="mouseUpHandleelse($event)" />
							</div>
						</div>

						<div style="margin-left: 75px;">
							<div class="poster-operate">
								<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

									<a-form-model-item label="海报文案" required>
										<a-textarea v-model="form.copywriting" :rows="4" />
									</a-form-model-item>

									<a-form-model-item required label="海报背景图">
										<div class="ft12 ftw400 cl-notice">建议上传375x680px</div>
										<div class="mt10 ml20">
											<upload-img v-model="form.back_img"></upload-img>
										</div>
									</a-form-model-item>

									<a-form-model-item required label="海报二维码信息">
										<div class="ml8">
											<div class="flex alcenter ">
												<div class="ft14 ftw500 cl-info">宽度：</div>
												<div>
													<a-input-number v-model="form.qrcode_info.width"
														placeholder="请输入"
														:precision="0" :min="1" :max="375" style="width: 120px;" />
												</div>
												<div class="ft14 ftw400 cl-notice">px</div>
											</div>

											<div class="flex alcenter">
												<div class="ft14 ftw500 cl-info">高度：</div>
												<div>
													<a-input-number v-model="form.qrcode_info.height"
														 placeholder="请输入"
														:precision="0" :min="1" :max="700" style="width: 120px;" />
												</div>
												<div class="ft14 ftw400 cl-notice">px</div>
											</div>

											<div class="flex alcenter">
												<div class="ft14 ftw500 cl-info">距顶部：</div>
												<div class="ft14 ftw500 cl-black">
													{{form.qrcode_info.y}}px
												</div>
											</div>

											<div class="flex alcenter">
												<div class="ft14 ftw500 cl-info">距左框：</div>
												<div class="ft14 ftw500 cl-black">
													{{form.qrcode_info.x}}px
												</div>
											</div>
										</div>
									</a-form-model-item>


									<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
										<a-button type="primary" @click="onSubmit" :loading="confirmLoading">
											确定保存
										</a-button>
									</a-form-model-item>
								</a-form-model>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	export default {
		components: {
			uploadImg,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 20
				},
				poster_template_id: 0,
				moveDataelse: {
					x: null,
					y: null
				},
				form: {
					copywriting: '',
					back_img: '',
					qrcode_info: {
						width: 120,
						height: 120,
						y: 0,
						x: 0,
					},
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.poster_template_id = 0;
			} else {
				this.poster_template_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed: {
			getTitle() {
				if (this.poster_template_id == 0) {
					return '添加海报';
				} else {
					return '编辑海报';
				}
			}
		},
		methods: {
			loaddata() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showShopPoster',{
					poster_template_id:this.poster_template_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},

			mouseDownHandleelse(event) {
				this.moveDataelse.x = event.pageX - this.$refs.qrcode.getBoundingClientRect().left
				this.moveDataelse.y = event.pageY - this.$refs.qrcode.getBoundingClientRect().top
				event.currentTarget.style.cursor = 'move'
				window.onmousemove = this.mouseMoveHandleelse
			},
			mouseMoveHandleelse(event) {
				this.form.qrcode_info.x = event.pageX - this.$refs.canvas.getBoundingClientRect().left - this.moveDataelse.x
				this.form.qrcode_info.y = event.pageY - this.$refs.canvas.getBoundingClientRect().top - this.moveDataelse.y
				if(this.form.qrcode_info.x<0){
					this.form.qrcode_info.x = 0;
					//window.onmousemove = null
				}
				if(this.form.qrcode_info.x>375 - this.form.qrcode_info.width){
					this.form.qrcode_info.x = 375 - this.form.qrcode_info.width;
					//window.onmousemove = null
				}
				
				if(this.form.qrcode_info.y<0){
					this.form.qrcode_info.y = 0;
					//window.onmousemove = null
				}
				if(this.form.qrcode_info.y>680 - this.form.qrcode_info.height){
					this.form.qrcode_info.y = 680 - this.form.qrcode_info.height;
					//window.onmousemove = null
				}
			},
			mouseUpHandleelse(event) {
				window.onmousemove = null
				event.currentTarget.style.cursor = 'move'
				console.log('鼠标松开了')
			},

			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.papi('platform/terminal/saveShopPoster',{
					poster_template_id:this.poster_template_id,
					copywriting: this.form.copywriting,
					back_img:this.form.back_img,
					qrcode_info: this.form.qrcode_info,
				}).then(res=>{
					this.$message.success('保存成功');
					this.confirmLoading=false;
					this.$router.go(-1);
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},

		}
	}
</script>

<style>
	.edit-poster-canvas {
		width: 375px;
		height: 680px;
		border: 1px solid #EBEDF5;
		position: relative;
	}

	.edit-poster-canvas-back-img {
		width: 375px;
		height: 680px;
		z-index: 999;
	}

	.edit-poster-canvas-qrcode {
		z-index: 1000;
		position: absolute;
	}

	.poster-operate {
		width: 750px;
		height: 100%;
		background: #F9F9F9;
		border: 1px solid #F0F3F5;
		padding: 30px;
	}
</style>
